import React, { Component } from "react";
import { map, take, takeRight, chain, value, isNull, isEmpty } from "lodash";
import { is } from "redux-saga/utils";
const $ = window.$;

/*
	Holds the Basic components which make up the Home component
	rendered on the /home app.
*/

export const NavBanner = () => {
  return (
    <section id="id" className="banner-area">
      {/*  Start Header Area */}
      <div
        id="undefined-sticky-wrapper"
        className="sticky-wrapper"
        style={{ height: "56" }}
      >
        <header className="default-header">
          <nav className="navbar navbar-expand-lg  navbar-light">
            <div className="container">
              <a
                className="navbar-brand"
                href="https://drive.google.com/file/d/1Ky3g9nYI3FbOOuNFxV1_nb8S6LlsTR0z/view?usp=sharing"
              >
                <img src="/images/logo.png" alt="" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="text-white lnr lnr-menu" />
              </button>

              <div
                className="collapse navbar-collapse justify-content-end align-items-center"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
                  <li>
                    <a href="#home">Home</a>
                  </li>
                  <li>
                    <a href="#service">Service</a>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#project">Projects</a>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>
      {/*  End Header Area */}
      <div className="container">
        <div className="row justify-content-start align-items-center">
          <div className="col-lg-6 col-md-12 no-padding banner-right">
            <img className="img-fluid" src="images/header.jpg" alt="" />
          </div>
          <div className="col-lg-6 col-md-12 banner-left">
            <h1 className="text-white">
              Hi, I’m <br />
              <span>Abhinav Sharma</span> <br />
              Freelancer
            </h1>
            <p className="mx-auto text-white  mt-20 mb-40">
              I sincerely believe that "The geek shall inherit the Earth."
              <br />
              People like to call me Foodie | Photographer | Book worm.
              <br />
              Sometimes all of them together.
              <br />
              Let's get together and create something cool.
              <br />
              <br />
              :)
            </p>
            <a href="#contact" className="text-uppercase header-btn">
              Let Us Chat!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export const Services = () => {
  return (
    <section id="service" className="service-area section-gap">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="menu-content pb-70 col-lg-8">
            <div className="title text-center">
              <h1 className="mb-10">Have a Look at my Services</h1>
              <p>We can do great things together.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="single-service col-lg-6 col-md-6 mb-30">
            <div className="icon">
              <img src="images/devops.png" alt="" />
            </div>
            <div className="dec">
              <h4 className="mt-30">
                <a href="#">DevOps</a>
              </h4>
              <p className="mt-20">
                I provide end to end Development, operational and maintenance
                services for web solutions.
                <br />I work mostly with JavaScript and it's assoicated
                frameworks( ex. React, Express, Hapi etc).
              </p>
            </div>
          </div>
          <div className="single-service col-lg-6 col-md-6 mb-30">
            <div className="icon">
              <img src="images/automation.png" alt="" />
            </div>
            <div className="dec">
              <h4 className="mt-30">
                <a href="#">Automation</a>
              </h4>
              <p className="mt-20">
                I can assist you in setting up Automation pipelines for your
                organizations workflows.
                <br />
                It will save you both time and money.
              </p>
            </div>
          </div>
          <div className="single-service col-lg-6 col-md-6">
            <div className="icon">
              <img src="images/iot.png" alt="" />
            </div>
            <div className="dec">
              <h4 className="mt-30">
                <a href="#">IoT Development</a>
              </h4>
              <p className="mt-20">
                I can help you build a smarter business model
                <br />
                by connecting physical components to the internet. Or even
                assist you on a cool project.
              </p>
            </div>
          </div>
          <div className="single-service col-lg-6 col-md-6">
            <div className="icon">
              <img src="images/consultation.png" alt="" />
            </div>
            <div className="dec">
              <h4 className="mt-30">
                <a href="#">Technical Consultation</a>
              </h4>
              <p className="mt-20">
                Most importantly, I can help you solve problems.
                <br />
                Have an idea but not sure how to implement it?
                <br />
                Hit me up and we'll work out a solution.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapSkills = skills => {
  /*
		maps an array of skills to an 
		array of div's.
		Renders the skills and their percents.
	*/
  return map(skills, (skill, idx) => {
    return (
      <div
        key={idx.toString()}
        className="skillbar"
        data-percent={skill.percent}
      >
        <div className="skill-bar-percent">{skill.name}</div>
        <div className="skillwrap">
          <div className="skillbar-bar" style={{ width: skill.percent }} />
        </div>
      </div>
    );
  });
};

const HireMeModal = () => {
  return (
    <div
      className="modal fade"
      id="hireMeModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="hireMeModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="hireMeModalTitle">
              Instructions
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Use the Contact Form below on the current page and send me message
            with the subject "Freelance Job Request".
            <br />
            Describe the job in the message body.
            <br />
            Additionally, you can also email your request to me with the same
            subject line as above.
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AboutMe = ({ skills }) => {
  return (
    <section id="about" className="about-area section-gap">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="menu-content pb-70 col-lg-8">
            <div className="title text-center">
              <h1 className="mb-10">About Myself</h1>
              <p>
                I am full time technologist and a part time photographer.
                <br />
                I also love food, movies & music.
                <br />
                I'm was born a bibliophile.
                <br />
                If you have a recommendation about a food, book, movie or music,
                feel free to drop a message and I'll check it out.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 about-left">
            <p>
              I have worked with a variety of technolgies and tools, but these
              are a few that I am most comfortable with.
            </p>
            <h4 className="pb-40">Technologies I work with</h4>
            {mapSkills(skills)}
            <div>
              <a
                href="https://www.codementor.io/abhinavsharma"
                className="genric-btn info circle arrow"
              >
                Know More
                <span className="lnr lnr-arrow-right" />
              </a>
            </div>
          </div>
          <div className="col-lg-6 about-right justify-content-end d-flex">
            <div className="myself-wrap">
              <img className="img-fluid" src="images/my-about.jpg" alt="" />
              <div className="desc">
                <h4>Abhinav Sharma</h4>
                <p className="pb-10">Developer for Hire</p>

                <p>
                  <span className="lnr lnr-envelope" />{" "}
                  sharma.abhinav503@gmail.com
                </p>
                <p>
                  <a href="https://www.codementor.io/abhinavsharma?utm_source=github&utm_medium=button&utm_term=abhinavsharma&utm_campaign=github">
                    <img
                      src="https://cdn.codementor.io/badges/contact_me_github.svg"
                      alt="Contact me on Codementor"
                      style={{ maxWidth: "100%" }}
                    />
                  </a>
                </p>
                <button
                  type="button"
                  className="mt-30 text-uppercase talk-btn"
                  data-toggle="modal"
                  data-target="#hireMeModal"
                >
                  Hire Me
                </button>
              </div>
            </div>
          </div>
        </div>
        <HireMeModal />
      </div>
    </section>
  );
};

export const Qualification = () => {
  return (
    <section className="qualification-area pt-100 pb-80">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="menu-content pb-20 col-lg-8">
            <div className="title text-center">
              <h1 className="mb-10">Work experience & Education</h1>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-6 col-md-6 qty-left">
            <div className="single-qly">
              <h4 className="text-uppercase pb-20">Amazon</h4>
              <p>June 2019 - August 2016</p>
              <h4 className="pt-20 pb-20">SDE Intern - CloudFront team</h4>
              <p>
                I worked on building a public feature for CloudFront. I used{" "}
                <b>React & Typescript</b> to build the new UI and used{" "}
                <b> Java </b> to write the CRUD API operations.
              </p>
            </div>
            <div className="btm-border d-block mx-auto" />
            <div className="single-qly">
              <h4 className="text-uppercase pb-20">NYU Tandon</h4>
              <p>October 2018 - Present</p>
              <h4 className="pt-20 pb-20">
                FullStack Developer - Graduate Assistant
              </h4>
              <p>
                I used <b>React, Redux, Apollo</b> to build responsive UI
                components from custom design.
                <br />I also deployed a <b>Firebase</b> based web push
                notificatiton service.
                <br />
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 qty-right">
            <div className="single-qly">
              <h4 className="pb-20">Masters in Science, Computer Science</h4>
              <p>
                <b>Session</b>: 2018-Present
              </p>
              <h4 className="pt-20 pb-20">New York University</h4>
              <p>
                My coursework includes - Artificial Intelligence, Foundation of
                Data Science, Computer Networks, Information Visualization,
                Human Computer Interaction and Programming Languages.
              </p>
            </div>
            <div className="btm-border d-block mx-auto" />
            <div className="single-qly">
              <h4 className="pb-20">
                Bachelor of Technology, Computer Science &amp; Engineering
              </h4>
              <p>
                <b>Session</b>: 2014-18
              </p>
              <h4 className="pt-20 pb-20">
                Maharaja Surajmal Institute of Technology
              </h4>
              <p>
                My coursework includes - Operation System, Data strucutre and
                Algorithms, Java, Computer Networks, Computer Architecture and
                Compiler Design.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapProjects = projects => {
  return map(projects, (project, idx) => {
    return (
      <div key={idx.toString()} className="section-top-border">
        <h4 className="mb-30">{project.title}</h4>
        <div className="row">
          <div className="single-project col-md-12 mt-sm-20">
            <p>{project.description}</p>
            {project.links !== undefined
              ? <div class="d-flex d-inline-flex">
                {
                  map(Object.keys(project.links), (key, idx) => {
                  return (
                    <a className={idx > 0 ? "pl-2" :""} idx={idx.toString()} href={project.links[key]}>
                      <p><b>{key}</b></p>  
                    </a>
                  );
                })
                }
              </div>
              : null}
            <h5 style={{ margin: "10px" }}>Built With:</h5>
            <ul style={{ display: "inline-flex" }} className="img-fluid">
              {map(project.techs, (tech, idx) => {
                return (
                  <li key={idx.toString()} style={{ margin: "10px" }}>
                    <img
                      style={{
                        float: "left",
                        margin: "10px",
                        height: `${tech.height}`,
                        width: `${tech.width}`
                      }}
                      src={"/images/" + tech.image}
                      alt=""
                    />
                    <h5>{tech.name}</h5>
                    <p>{tech.description}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  });
};

export const Projects = ({ projects }) => {
  return (
    <section id="project" className="project-area section-gap">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="menu-content pb-70 col-lg-8">
            <div className="title text-center">
              <h1 className="mb-10">Jobs and Projects</h1>
              <p>
                Here a few freelance jobs and personal Projects
                <br />
                that I've worked on so far.
              </p>
            </div>
          </div>
        </div>
        {/*<div className="section-top-border">
					<h4 className="mb-30">Pool Monitoring System</h4>
					<div className="row">
						
						<div className="single-project col-md-12 mt-sm-20">
						<p>
							The job involved setting up a monitoring system 
							for pools and aquariums.
							I assisted in building it using a Raspberry Pi
							and developed a cloud service with a common dashboard
							to monitor multiple pools.
						</p>
						<h5 style={{margin: '10px'}}>Built With:</h5>	
						<ul style={{display:'inline-flex'}} className="img-fluid">
							
							<li>
								<img style={{float:'left', margin: '10px'}} src="/images/python.png"/>
								<h5>Python</h5>
								<p>Used for writing script to gather sensor values periodically.</p>
							</li>
							<li>
								<img style={{float:'left', margin: '10px'}} src="/images/nodejs.png"/>
								<h5>Node Js</h5>
								<p>Minimal server to backup value to cloud</p>
							</li>
							<li>
								<img style={{float:'left', margin: '10px'}} src="/images/rpi.png"/>
								<h5>Raspberry Pi</h5>
								<p>Allowed 24x7 monitoring of pools pH and temprature value.</p>
							</li>
						</ul>
							
						</div>
					</div>
				</div> */}
        {mapProjects(projects)}
      </div>
    </section>
  );
};

export class ContactMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formMessage: "Thank you for reaching out"
    };
  }

  submitContactForm(e) {
    e.preventDefault();

    let formMessage = this.state.formMessage;
    const form = document.getElementById("homeContact");
    let [name, email, subject, message] = [
      form[0].value,
      form[1].value,
      form[2].value,
      form[3].value
    ];
    let contactFrom = "home";
    if (isNull(name) || isEmpty(name)) {
      formMessage = "Please enter a value in the name field";
    } else if (isNull(email) || isEmpty(email)) {
      formMessage = "Please enter a value in the email field";
    } else if (isNull(subject) || isEmpty(subject)) {
      formMessage = "Please enter a value in the subject field";
    } else if (isNull(message) || isEmpty(message)) {
      formMessage = "Please enter a value in the message field";
    } else {
      this.props.sendEmail({ name, email, subject, message, contactFrom });
    }
    this.setState((state, props) => {
      return {
        formMessage
      };
    });
    $("#successEmailModal").modal("show");
  }

  render() {
    return (
      <section id="contact" className="contact-area section-gap">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="menu-content pb-20 col-lg-8">
              <div className="title text-center">
                <h1 className="mb-10">Let's get in touch!</h1>
                <p>
                  I'd love to hear from you. Feel free to start a conversation.
                </p>
              </div>
            </div>
          </div>
          <form className="form-area mt-60" id="homeContact">
            <div className="row">
              <div className="col-lg-6 form-group">
                <input
                  name="name"
                  placeholder="Enter your name"
                  onFocus={e => (e.target.placeholder = "")}
                  onBlur={e => (e.target.placeholder = "Enter your name")}
                  className="common-input mb-20 form-control"
                  required=""
                  type="text"
                />

                <input
                  name="email"
                  placeholder="Enter email address"
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                  onFocus={e => (e.target.placeholder = "")}
                  onBlur={e => (e.target.placeholder = "Enter your email")}
                  className="common-input mb-20 form-control"
                  required=""
                  type="email"
                />

                <input
                  name="subject"
                  placeholder="Enter your subject"
                  onFocus={e => (e.target.placeholder = "")}
                  onBlur={e => (e.target.placeholder = "Enter your subject")}
                  className="common-input mb-20 form-control"
                  required=""
                  type="text"
                />
              </div>
              <div className="col-lg-6 form-group">
                <textarea
                  className="common-textarea mt-10 form-control"
                  name="message"
                  placeholder="Messege"
                  onFocus={e => (e.target.placeholder = "")}
                  onBlur={e => (e.target.placeholder = "Enter your message")}
                  required=""
                />
                <button
                  onClick={e => this.submitContactForm(e)}
                  className="primary-btn mt-20"
                >
                  Send Message
                  <span className="lnr lnr-arrow-right" />
                </button>
                <div
                  className="modal fade"
                  id="successEmailModal"
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="successEmailModalTitle"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="successEmailModalTitle">
                          {this.state.formMessage}
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export const Footer = () => {
  return (
    <footer className="footer-area section-gap">
      <div className="container">
        <div className="row">
          <div className="col-lg-5  col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h6>Let's get in touch</h6>
              {/*  Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              <p className="footer-text">
                I'll try to respond as soon as possible!
              </p>
              {/*  Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
            </div>
          </div>
          <div className="col-lg-5  col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h6>Subscribe to my blog</h6>
              <p>Stay update with the latest posts</p>
              <div className="" id="mc_embed_signup">
                <form target="_blank" noValidate="true" className="form-inline">
                  <div className="d-flex flex-row">
                    <input
                      className="form-control"
                      name="EMAIL"
                      placeholder="Enter Email"
                      onFocus={e => (e.target.placeholder = "")}
                      onBlur={e => (e.target.placeholder = "Enter email")}
                      required=""
                      type="email"
                    />

                    <button className="click-btn btn btn-default">
                      <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                      />
                    </button>
                    <div
                      style={{
                        position: "absolute",
                        left: "-5000px"
                      }}
                    >
                      <input
                        name="b_36c4fd991d266f23781ded980_aefe40901a"
                        tabIndex="-1"
                        value=""
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="info" />
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 social-widget">
            <div className="single-footer-widget">
              <h6>Reach out to me</h6>
              <p>Let's talk!</p>
              <div className="footer-social d-flex align-items-center">
                <a href="https://www.facebook.com/abhinav.sharma.35380399">
                  <i className="fa fa-facebook" />
                </a>
                <a href="https://twitter.com/bh1nav">
                  <i className="fa fa-twitter" />
                </a>
                <a href="http://github.com/ExC0d3">
                  <i className="fa fa-github" />
                </a>
                <a href="https://www.linkedin.com/in/excode/">
                  <i className="fa fa-linkedin" />
                </a>
                <a href="https://www.instagram.com/abheeenav/">
                  <i className="fa fa-instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
