import produce from 'immer';
import story from './story';

/*
	Reducer from BlogArea components state
*/

const stories = [{
		id: '1',
		title: "Why I love winter: A short story",
		date: "January 30, 2018",
		image: "images/1.jpg",
		hashtags: ["#winter", "#love", "#snow", "#january"],
		comments: 2,
		content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
						Praesent vel tortor facilisis, volutpat nulla placerat,
						tincidunt mi. Nullam vel orci dui. Suspendisse sit amet
						laoreet neque. Fusce sagittis suscipit sem a consequat.
						Proin nec interdum sem. Quisque in porttitor magna, a
						imperdiet est.`
	}
];

// defined the initial state of the component
const initialState = {
	loading: true,
	currentPage: 1,
	stories
}



const blogArea = (state = initialState, action) => {
	/*
		Produce creates a copy of the state in draft and automatically
		returns it if not explicity returned.
		At the end all the modifications to draft will create a new
		deep copy of the state object.

		This is necessary because we should never modify the state 
		object directly.
	*/
	return produce(state, draft => {
		
		/*
			Based on the action that is dispacted,
			we modify the state.
		*/

		switch(action.type){

			case 'REQUEST/STORIES':
				/*
					while a request is being made to the API
					the the component should have loading state.
				*/

				draft.loading = true;
				draft.error = null;
				break;

			case 'SUCCESS/STORIES':
			/*
				On Successful response from the API
				loading is false.
				Story and activities received as payload
				override the default state.
			*/

				draft.loading = false;
				draft.stories = action.payload.stories;
				break;

			case 'FAILURE':
			/*
				On API failure loading is false
				so that error screen shows the error.
			*/

				draft.loading = false;
				draft.error = action.error;
				break;

			case 'AddPages':

			/*
				AddPages assign a page number to each story
				so that they are displayed on corresponding
				pages.
				
			*/
				// we are only passing a state slice to the 
				// sub reducer.
				draft.stories = story.addPages(draft.stories, action)
				break;

			case 'GoToPage':
			/*
				When we navigate to a different page
				we need to update the state to point
				to that page.
			*/
				const {pageNum} = action.payload;
				draft.currentPage = pageNum;
				
				break;

			default:
				return draft;
		}
	});
}

export default blogArea;