import { storyBy, map, chain, value } from "lodash";
import produce from "immer";

/*
	Sub Reducer for Stories in the blog Area
*/

const story = {

	/*
		just receives state.blogArea.stories - 
		a small slice of the whole state.
	*/

	addPages: (state, action) => {
		return produce(state, draft => {

			if (action.type === "AddPages") {
				
				/*
					add Page numbers to the stories
					and return the updated state slice.
				*/	
				const modifiedStories = addPagetoStories(draft);
				
				draft = modifiedStories;
			} else {
				return draft;
			}
		});
	}
};

const addPagetoStories = stories => {
	/*
		Add's a page number to each story.

		Logic for deciding page number for each story
		
		1) Sort the stories by date
		2) Assign Page 1 to first 3 stories
		3) Assign Page 2 to next 3 stories
		and so on
	*/
	let startPage = 1;

	let filteredStories = chain(stories)
		.sortBy(story => story.date) // id's of the stories
		.map((story, idx) => {
			// console.log('Modifying: ', story);
			story["pageNum"] = startPage;
			if ((idx + 1) % 3 === 0) {
				startPage += 1;
			}
			return story;
		})
		.value();
	return filteredStories;
};

export default story;
