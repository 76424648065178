import { createActions } from 'redux-actions';

/*
	Define API actions for dispatching to reducers.
	3 major actions to be dispatched when
	REQUEST - making requests to API.
	SUCCESS - receiving successful response from API.
	FAILURE - failure occurs while making API calls.
*/

const ApiActions = createActions({
		REQUEST: {
			/*
				Every key corresponds to an endpoint that is called.
				Payload of the Action is spread as data passed while 
				dispatching the action.
				Example - /stories?quantity=6 is equal to dispatching
				REQUEST.STORIES({quantity:6})
			*/
			ABOUT: data => ({...data}),
			STORIES: data => ({...data}),
			CONTACT: {
				GET: {
					RECENT_STORIES: data => ({...data})
				},
				POST: {
					QUERY: data => ({...data})
				}
			}
		},
		SUCCESS: {
			/*
				Every key corresponds to the endpoint which is expected
				to reply to the request.
				Any data received from the API request is spread as payload
			*/
			ABOUT: data => ({...data}),
			STORIES: data => ({...data}),
			CONTACT: data => ({...data})
		},
		FAILURE: undefined
});

export default ApiActions;