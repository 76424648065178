import { takeLatest, put, call } from 'redux-saga/effects';
import axios from 'axios';
import Actions from '../Actions/root';

const fetchStories = (quantity) => {
	/*
		makes get request to /stories endpoint
		and passes quantity as the parameter.

		returns the retrieved values.
	*/
	return axios({
		method:'get',
		url: `http://localhost:5000/stories`,
		params: {
			quantity
		}
	})
}

export default function *blogSaga(action){

	//action object received as the argument from `call` in the apiSaga
	
	const {quantity} = action.payload;

	try{

		/*
			fetch the stories for the blog area
			and parse it response.
		*/

		const response = yield call(fetchStories, quantity);

		const {stories} = response.data;
		
		// dispatch an SUCCESS/STORIES action to update the state
		yield put(Actions.API.success.stories({stories}));
	}
	catch(error) {
		// on error dispatch FAILURE action
		yield put({
			type: 'FAILURE',
			error
		})
	}

}
