import produce from 'immer';
import { has } from 'lodash';

const story = {
	date: new Date(Date.now()),
	content: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, harum id distinctio unde quasi, nihil consectetur sed nostrum temporibus libero tempore. Quaerat maiores obcaecati officiis dolores ut magni et placeat.`,
	title: 'Contact me or Just say hi',
	hashtags: ["#contact Me", "#LetsGetInTouch"]
}

const recentStories = [
	{
		image: "images/thumb-1.jpg",
		date: "January 30, 2018",
		title: "My fall in love story",
		hashtags: ["#winter", "#love", "#snow", "#january"],
		id: 1
	},
	{
		image: "images/thumb-2.jpg",
		date: "February 30, 2018",
		title: "Random 2",
		hashtags: ["#winter", "#love", "#snow", "#january"],
		id: 2
	}
];

const intialState = {
	loading: true,
	story,
	recentStories,
}



const contact = (state = intialState, action) => {
	/*
		Produce creates a copy of the state in draft and automatically
		returns it if not explicity returned.
		At the end all the modifications to draft will create a new
		deep copy of the state object.

		This is necessary because we should never modify the state 
		object directly.
	*/

	return produce(state, draft => {

		switch(action.type){

			case 'REQUEST/CONTACT/GET/RECENT_STORIES':
				return draft;
			break;

			case 'REQUEST/CONTACT/POST/QUERY':
				// console.log('received', action.payload);
				return draft;
				break;

			case 'SUCCESS/CONTACT':
				
				if( has(action.payload, 'query') ){
					return draft;
				}
				else{
					draft.loading = false;
					let { recentStories } = action.payload;
					draft.recentStories = recentStories;
				}

			break;

			default: 
				return draft;
			
		}

	});
};

export default contact;
