import { createActions } from 'redux-actions';

/*
	Create actions for manipulating the Stories shown
*/

const StoryActions = createActions({
	'AddPages':undefined, // Add pages to the stories that will be shown in the blog area.
	'GoToPage':pageNum => ({pageNum}) // navigate to a particular page in the blog area.
});

export default StoryActions;