import { takeLatest, call, put } from "redux-saga/effects";
import axios from "axios";
import { map } from "lodash";
import Actions from '../Actions/root';

/*
	Handle API request for About component.
	Fetches data for it and dispatches SUCCESS/ABOUT action
	which causes the state to be updated.
*/

const fetchAboutStory = () => {
	/*
		makes the actual api request to /about endpoint
		and return the data obtained.
	*/
	return axios({
		method: "get",
		url: `http://localhost:5000/about`
	});
};

export default function* aboutSaga(action) {

	//action object received as the argument from `call` in the apiSaga
	
	try {

		/*
			fetch the data for About component and parse
			aboutStory and activities from it.
		*/
		const response = yield call(fetchAboutStory);

		const { aboutStory, activities } = response.data;

		// convert string date to Date object
		aboutStory.date = new Date(aboutStory.date);

		// map all the dates of the stories from strings to Date objects
		let updatedActivities = map(activities, activity => {
			let temp = activity.date;
			return { ...activity, date: new Date(temp) };
		});

		/*
			dispatch a SUCCESS/ABOUT action with it's payload to update
			the state.
		*/

		yield put(Actions.API.success.about({aboutStory, activities: updatedActivities}))
	
	} catch (error) {

		// on error dispatch FAILURE
		yield put({ type: "FAILURE", error });
	}
}
