import produce from 'immer';

/*
	Reducer for the About component's state.
*/

// define the initial state for the component
const initialState = {
	loading: true,
	story : {
		id: "1",
		date: new Date("January 30, 2018"),
		title: "Hi, I am Abhinav",
		content: "This page is still waiting for it's content. Please recheck in sometime",
		featureImage: "images/about.jpg",
		hashtags: ["#myStory", "#aboutMe"],
		image: "images/about-2.png",
		comments: 2
	},
	activities: [{
		date: new Date("January 30, 2018"),
		achievement: "XYZ",
		description: "ABC"
	}]
}


const about =  (state = initialState, action) => {
	/*
		Produce creates a copy of the state in draft and automatically
		returns it if not explicity returned.
		At the end all the modifications to draft will create a new
		deep copy of the state object.

		This is necessary because we should never modify the state 
		object directly.
	*/
	return produce(state, draft => {

		/*
			Based on the action that is dispacted,
			we modify the state.
		*/

		switch(action.type){
			case 'REQUEST/ABOUT':
				/*
					while a request is being made to the API
					the the component should have loading state.
				*/
				draft.loading = true;
				draft.error = null;
				break;
				

			case 'SUCCESS/ABOUT':

				/*
					On Successful response from the API
					loading is false.
					Story and activities received as payload
					override the default state.
				*/
				draft.loading = false;
				draft.story = action.payload.aboutStory;
				draft.activities = action.payload.activities;
				break;
				
			
			case 'FAILURE':

				/*
					On API failure loading is false
					so that error screen shows the error.
				*/
				draft.loading = false;
				draft.story = null;
				draft.error = action.payload.error;
				break;
			
			default:
				return draft;
		}
		
	});
}

export default about;