import { call, put } from "redux-saga/effects";
import axios from "axios";
import Actions from '../Actions/root';

/*
	Handle API request for Contact component.
	Fetches data for it and dispatches SUCCESS/ABOUT action
	which causes the state to be updated.
*/

const sendContactQuery = (data) => {
	/*
		make Post request to API and send the name,
		email and text of the contact query. 
	*/
	return axios({
		method: 'post',
		url: 'https://lit-harbor-22895.herokuapp.com/contact',
		data
	})
}

const fetchRecentStories = () => {
	return axios({
		method: 'get',
		url: 'http://localhost:5000/contact/recentStories'
	})
}

export default function* contactSaga(action){

	try{

		let response = '';
		switch(action.type){

			case 'REQUEST/CONTACT/GET/RECENT_STORIES':

				//fetch the recent stories to be displayed in contact section
				response = yield call(fetchRecentStories);

				const { recentStories } = response.data;

				// dispatch success with recentStories
				yield put(Actions.API.success.contact({recentStories}));

			break;

			case 'REQUEST/CONTACT/POST/QUERY':

				// const {name, email, text} = action.payload;

				// console.log(action.payload);

				//send contact query to API
				response = yield call(sendContactQuery, {...action.payload});

				if(response.statusText==='OK'){
					yield put(Actions.API.success.contact({query: 'ok'}));
				}
			break;

			default:
				yield put(Actions.API.failure())
		}

	}
	catch(error){
		yield put({type: 'FAILURE', error});
	}
}

