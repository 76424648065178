import React, { Component } from "react";
import "../css/poppins.css";
import "../css/linearicons.css";
import "../css/magnific-popup.css";
import "../css/owl.carousel.css";
import "../css/main.css";
import {
	AboutMe,
	ContactMe,
	Footer,
	NavBanner,
	Services,
	Projects,
	Qualification
} from '../Basic Components/HomeSection';
import {connect} from 'react-redux';
import Actions from '../Actions/root';

class Home extends Component {
	render() {
		return (
			<div>

				<NavBanner></NavBanner>

				<AboutMe skills={this.props.skills}></AboutMe>
				<Services></Services>


				<Qualification></Qualification>

				<Projects projects={this.props.projects}></Projects>

				<ContactMe sendEmail={this.props.sendEmail}></ContactMe>				

				<Footer></Footer>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		projects: state.home.projects,
		skills: state.home.skills
	}
};

const mapDispatchToProps = (dispatch) => {
	return{
		sendEmail: ({contactFrom, name, email, subject, message}) => {
			/*
				function to dispatch an Action to send contact
				query to the API
			*/
			dispatch(Actions.API.request.contact.post.query({contactFrom, name, email, subject, message}));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
