import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css'
import './css/swiper.min.css';
import './css/style.css';
import Home from './Main Components/Home';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

/*
    Render the root component.
    It receives the store prop.
    We use Provider from react-redux
    which wraps the Router enabling all the children
    and grandchilren to access the store.
*/
const Root = ({store}) => (
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route strict exact path='*' component={Home}></Route>
      </Switch>
    </BrowserRouter>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root;
