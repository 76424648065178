import { combineReducers } from 'redux';

import about from './about';
import blogArea from './blogArea';
import contact from './contact';
import home from './home';

export default combineReducers({
	about,
	blogArea,
	contact,
	home
});

