import produce from 'immer';

const initialState = {
	projects: [
	{
		title: 'Proximity Marketing App - Hack Cooper',
		description: `Built a progressive web app to allow local businesses to push deals to their customers
		as they walk by. Programmed backend in Node.js and designed the frontend with Bootstrap.`,
		techs:[
			{
				name: 'Node Js',
				image: 'nodejs.png',
				description: 'API service for the app.'
			}, 
			{
				name: 'Bootstrap',
				image: 'bootstrap.png',
				description: 'Build a responsive UI using bootstrap.',
				width: '48px'
			},
			{
				name: 'AWS',
				image: 'aws.png',
				description: 'Deployed the backend service on AWS EC2 instance.',
				width: '48px'
			}
		],
		links: {
			Github: 'https://github.com/ExC0d3/hack-cooper',
			Presentation: 'https://docs.google.com/presentation/d/1n7hpv3yTKOHZDNe4vMRA6zT2Y6CQGx8CcTiMQvBnyhk/edit?usp=sharing'
		}
	},
	{
		title: 'Haptic Feedback System - Hack Dibner',
		description: `Developed a local system that aids visually challenged people with navigation inside a
		building or closed location. Created the mobile app with React-Native & backend with Node.js.`,
		techs:[
			{
				name: 'Node Js',
				image: 'nodejs.png',
				description: 'API service for the app.'
			}, 
			{
				name: 'React',
				image: 'react.svg',
				description: 'Built a mobile UI using React-Native.',
				width: '48px'
			}
		],
		links: {
			Github: 'https://github.com/utkarshp21/Hack-Dibner',
			Presentation: 'https://docs.google.com/presentation/d/15GDm7SKqrWMBc5LNRT3YsNpH10InH0WADSQP20oG-K8/edit?usp=sharing'
		}
	},
	{
		title: 'Miru - Hack Washigton',
		description: `Created a progressive app that delivers semantic audio description of surrounding to blind people
		when they travel. Integrated voice control using Web Speech API. Developed the frontend with HTML5/CSS3 and backend with Node.js,
		Microsoft Computer Vision API. Deployed the service on Azure.`,
		techs:[
			{
				name: 'Node Js',
				image: 'nodejs.png',
				description: 'API service for the app.'
			}, 
			{
				name: 'Azure',
				image: 'azure.png',
				description: 'Deployed the service on Azure.',
				width: '80px',
				height: '48px'
			}
		],
		links: {
			Github: 'https://github.com/ExC0d3/HackWashington2k19',
		}
	},
	{
		title: 'VR Exposure Therapy - Hack NYU',
		description: `Our project embeds the user in a virtual reality environment and based on their fears or anxiety conditions, it generates a scenario for them to experience. The scenario gets increasingly more difficult as time passes. Alexa initiates a phobia skill to calm down the user during the interaction. A fit-bit is used to record his vitals and displayed on a dashboard for Doctor's to see in real-time.`,
		techs:[
			{
				name: 'Node Js',
				image: 'nodejs.png',
				description: 'API service for the app.'
			},
			{
				name: 'React',
				image: 'react.svg',
				description: 'Built a mobile UI using React-Native.',
				width: '48px'
			},
			{
				name: 'Occulus',
				image: 'occulus.png',
				description: 'Built the exposure theory game levels in Occulus.',
				width: '48px'
			}
		],
		links: {
			Github: 'https://github.com/utkarshp21/HackNYU',
			Presentation: 'https://docs.google.com/presentation/d/1OQinvN5ycW2C-NFqjJJQVHvs2U4M4IkVU8bQab--gCc/edit?usp=sharing'
		}
	},
	{
		title: 'Pool Monitoring System',
		description: `The job involved setting up a monitoring system 
							for pools and aquariums.
							I assisted in building it using a Raspberry Pi
							and developed a cloud service with a common dashboard
							to monitor multiple pools.`,
		techs:[
			{
				name: 'Python',
				image: 'python.png',
				description: 'Used for writing script to gather sensor values periodically.'
			},
			{
				name: 'Node Js',
				image: 'nodejs.png',
				description: 'Minimal server to backup value to cloud.'
			},
			{
				name: 'Raspberry Pi',
				image: 'rpi.png',
				description: 'Allowed 24x7 monitoring of pools pH and temprature value.'
			}
		]
	},
	{
		title: 'Gesture Recognition using HF Radio Waves',
		description: `This was my undergraduate minor research project.
								I used an Arduino based system with a CW Doppler Radar
								to build a micro gesture recognition system.`,
		techs: [
			{
				name: 'Arduino',
				image: 'arduino.png',
				description: `System was based on Arduino to use it's analog capabilities.` 
			},
			{
				name: 'Matlab',
				image: 'matlab.png',
				description: `The ML code was structured with Matlab.`
			},
			{
				name: 'Radar Sensor',
				image: 'doppler.png',
				description: `Used to capture micro-doppler features of gestures.`
			}
		]
	},
	{
		title: 'Excel Automation',
		description: `I worked on this project as a Freelancer for
								Boston Scientific India. 
								It facilitated sending automated emails from 
								to vendors at the end of a sales day. 
								It reduced the man hours for the sales team by 25% on a daily basis.`,
		techs: [
			{
				name: 'VBA Scripting',
				description: 'Created an automated macro for sending emails to vendors.',
				image: 'excel.png'
			}
		]
	}
],
skills: [
	{
		name: 'Java - Proficient',
		percent: '85%'
	},
	{
		name: 'Javascript - Excellent',
		percent: '90%'
	},
	{
		name: 'Linux - Most Used',
		percent: '85%'
	},
	{
		name: 'AWS - Most Used',
		percent: '75%'
	},
	{
		name: 'Python - Comfortable',
		percent: '70%'
	}
]
};

const home = (state = initialState, action) => {

	return produce(state, draft => {

		switch(action.type){
			default:
				return draft;
		}
	});
}

export default home;
