import StoryActions from './story';
import ApiActions from './api';

/*
	Combine the all the actions under a single Object.
*/
const Actions = {
	API: ApiActions,
	STORY: StoryActions
};

export default Actions;